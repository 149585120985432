import React from 'react';
import styles from './Card.module.scss';

type Props = {
  text: string | undefined;
  isVisible: boolean;
};

const Description = ({ text, isVisible }: Props) => {
  if (text && isVisible) {
    return <p className={styles.description}>{text}</p>;
  }
  return null;
};

export default Description;
